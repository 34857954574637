<template>
    <div class="vx-row md:w-full">
            <div class="vx-col md:w-1/4">
                <CameraTree :is-map="true"
                            @onMouseDownNode="nodeSelected">
                </CameraTree>
            </div>
            <div class="vx-col md:w-3/4">
                <div v-if="!isCameraShare" class="vx-row mb-3 mt-2 justify-content-right">
                    <div class="vx-col flex items-center">
                        <vx-tooltip
                            color="warning"
                            :text="$t('textCamera.textInstructionOnMap')">
                            <feather-icon class="mr-2 items-center" color="warning"
                                          type="border"
                                          icon-pack="feather"
                                          icon="HelpCircleIcon"
                                          style="color: rgba(var(--vs-warning), 1) !important">
                            </feather-icon>
                        </vx-tooltip>

                        <vx-tooltip v-if="!isEditOnMap"  :text="$t('onMap.textCancel')" position="top" >
                            <feather-icon class="mr-6 items-center" color="primary"
                                          type="border"
                                          icon-pack="feather"
                                          icon="LogOutIcon"
                                          style="color: #1aaa55"
                                          @click="clickCancelChanger">
                            </feather-icon>
                        </vx-tooltip>

                        <vx-tooltip v-if="!isEditOnMap" :text="$t('onMap.deletePositionCamera')" position="top">
                            <feather-icon class="mr-5  items-center" color="primary"
                                          type="border"
                                          icon-pack="feather"
                                          icon="Trash2Icon"
                                          style="color: #1aaa55"
                                          @click="clickSaveChanger">
                            </feather-icon>
                        </vx-tooltip>
                        <vs-button   v-if="isEditOnMap "   @click="isHandleEditOnMap">
                            {{ $t('menuCamera.setUpLocation')}}</vs-button>
                    </div>

                </div>
                <div v-if="isCameraShare" class="vx-row mb-3 mt-2">
                    <div class="vx-col md:w-full  mt-2 flex flex-wrap items-center justify-end">
                        <div class="vx-col md:w-1/6 mt-2 flex flex-wrap items-center justify-end">
                            <vx-tooltip
                                color="warning"
                                :text="$t('textCamera.textInstructionOnMap')">
                                <feather-icon class="mr-5" color="primary"
                                              type="border"
                                              icon-pack="feather"
                                              icon="HelpCircleIcon"
                                              style="color: #1aaa55">
                                </feather-icon>
                            </vx-tooltip>
                        </div>
                        <div class="vx-col">
                               <span class="mr-2"
                                     style="font-size: 15px;font-weight: 500;">{{$t('onMap.textTitleEditCamera')}}
                               </span>
                        </div>
                    </div>
                </div>

                <div class="vx-col md:w-full w-full mt-2 mb-5">
                    <label class="vs-input--label"> {{ $t('onMap.search') }}</label>
                    <gmap-autocomplete id="map"
                                       class="vs-inputx vs-input--input normal hasValue"
                                       @place_changed="setPlace"
                                       :value="InputPlaceValue">
                    </gmap-autocomplete>
                </div>
                <div>
                    <GmapMap
                        ref="mapRef"
                        :center="center"
                        :zoom="zoomMap"
                        map-type-id="terrain"
                        style="width: 100%; height: 50rem; opacity: 1; position: relative; overflow: hidden;"
                        @click="mark">
                        <gmap-info-window :options="infoOptions" :position="infoPosition" :opened="infoOpened" @closeclick="infoOpened=false" >
                            <div class="vx-row ml-1 mr-1 mb-1 mt-1" >
                                <h6 class="mr-2 text-bold">{{infoContent}}</h6>
                            </div>
                            <div  class="vx-row ml-1 mr-1 mb-1 mt-1" style="color: black;font-weight: 400;max-width: 280px;">
                                {{ contentFullAddress}}</div>
                            <div v-if="contentNumberPhone" class="vx-row ml-1 mr-1 mb-1 mt-1" style="color: black; font-weight: 400;"> Điện thoại: 
                                <span class="ml-1" style="color: red"> {{contentNumberPhone}} </span></div>
                        </gmap-info-window>
                        <GmapMarker
                            :key="index"
                            v-for="(m, index) in showDataInMap"
                            :position="getPosition(m.position)"
                            :clickable="true"
                            :draggable="false"
                            country="vi"
                            @click="toggleInfo(m, index)"
                        />
                    </GmapMap>
                </div>
            </div>
        <div v-if="isOpacity" >
            <img src="../../../../assets/images/pages/location.png" height="30" width="30" :style="{left: dataMouseMove.x - 302 + 'px', top: dataMouseMove.y - 215 + 'px' }" style=" position: absolute"/>
            <span  :style="{left: dataMouseMove.x - 270 + 'px', top: dataMouseMove.y - 210 + 'px' }" style=" position: absolute">{{titleCamera}}</span>
        </div>
    </div>
</template>

<script>
import "@/assets/scss/vuexy/extraComponents/tree.scss";
import {gmapApi} from 'vue2-google-maps'
import * as VueGoogleMaps from "vue2-google-maps";
import CameraTree from '../../../../components/camera-tree/CameraTree.vue';
export default {
    name: "OnMap",
    components: {
        CameraTree,
        VueGoogleMaps
    },
    computed: {
        google: gmapApi
    },
    data() {
        return {
            InputPlaceValue: '',
            isCameraShare: false,
            showDataInMap: [],
            infoPosition: null,
            infoContent: null,
            infoOpened: false,
            infoCurrentKey: null,
            infoOptions: {
                pixelOffset: {
                    width: 0,
                    height: -35
                }
            },
            isJustDeleteLocation: false,
            isCameraTitle: false,
            isJustUpdateLocation: false,
            checkCameraInMap: true,
            zoomMap: 14,
            isCameraDelete: false,
            treeData: [{
                isOrganization: true,
                title: null,
                expanded: true,
                children: []
            }],

            cameraIdMarkers: null,
            dropLocation: {},
            coordinates: null,
            isOpacity: 0,
            dataMouseMove: {
                x: 0,
                y: 0
            },
            draggable: false,
            isEditOnMap: true,
            titleCamera: null,
            options: {
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUi: false
            },
            address: '',
            addressString: '',
            center: {
                lat: 21.030653,
                lng: 105.847130
            },

            places: [],
            currentPlace: null,
            mouseup: false,
            valueCameraInTree: {},
            hoverDeleteCamera: false,
            organizationBranchAll: null,
            contentFullAddress: null,
            contentEmail: null,
            contentNumberPhone: null,
            contentBranchName: null
        }
    },
    watch: {
        showDataInMap: {
            handler: function (val) {
                this.showDataInMap = val;
            },
            deep: true
        },
    },
    props: {
        reLoadTree: {
            type: Function
        }
    },
    created() {
        this.$crm.post('/organization-branch/all').then((response) => {
            this.organizationBranchAll = response.data;
        }).catch((err) => {
            return this.$vs.notify({
                text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
            });
        });
    },
    mounted() {
        this.$eventBus.$on('sumObjValueCameraMap', ($event) => {
            if ($event) {
                this.showDataInMap = [];
                this.showDataInMap = $event;
                this.showDataInMap.map(x => {
                    let org = this.organizationBranchAll.find(p => p.id == x.position.organizationBranchId)
                    x.position.contentFullAddress = org.fullAddress ? org.fullAddress : null,
                    x.position.contentNumberPhone = org.phoneNumber ? org.phoneNumber : null,
                    x.position.contentBranchName = org.organizationBranchName ? org.organizationBranchName: null
                })
            }
        })
        let result =  this.google;
        this.geolocate();
        window.addEventListener('mousemove', e => {
            this.mouseup = false;
            this.dataMouseMove = {
                x: e.clientX,
                y: e.clientY
            }
        });
        window.onwheel = e => {
            if(e.deltaY >= 0){
                window.addEventListener('mousemove', et => {
                    this.mouseup = false;
                    this.dataMouseMove = {
                        x: et.pageX,
                        y: et.pageY
                    }
                });
            } else {
                window.addEventListener('mousemove', et => {
                    this.mouseup = false;
                    this.dataMouseMove = {
                        x: et.pageX,
                        y: et.pageY
                    }
                });
            }
        }
    },

    methods: {
        getPosition: function(m) {
            return {
                lat: parseFloat(m.lat),
                lng: parseFloat(m.lng)
            }
        },
        toggleInfo: function(m, index) {
            this.infoPosition = this.getPosition(m.position);
            this.infoContent = m.position.cameraName;
            this.contentFullAddress = m.position.contentFullAddress ? m.position.contentFullAddress : null,
            this.contentNumberPhone = m.position.contentNumberPhone ? m.position.contentNumberPhone : null,
            this.contentBranchName = m.position.contentBranchName ? m.position.contentBranchName: null
            if (this.infoCurrentKey == index) {
                this.infoOpened = !this.infoOpened;
            } else {
                this.infoOpened = true;
                this.infoCurrentKey = index;
            }
        },
        mark(event) {
            if(this.isEditOnMap == false && this.isCameraDelete == true){
                this.hoverDeleteCamera = true;
                const marker = {
                    lat:  event.latLng.lat(),
                    lng: event.latLng.lng(),
                    id: this.cameraIdMarkers
                };
                const markerPayload = {
                    latitude: event.latLng.lat(),
                    longitude: event.latLng.lng()
                }
                this.showDataInMap.push({position: marker});
                this.places.push(this.currentPlace);
                this.center = marker;
                this.updateMarkersCamera(markerPayload);

            }
        },
        updateMarkersCamera(markerPayload){
            if(this.isEditOnMap == false && this.isCameraDelete == true){
                this.$vs.loading();
                this.$crm.post(`/camera/update-lat-lng?cameraId=${this.cameraIdMarkers}`, markerPayload).then(() => {
                    this.$vs.loading.close();
                    this.infoPosition = {
                        lat: markerPayload.latitude,
                        lng: markerPayload.longitude
                    };
                    this.infoContent = this.titleCamera;
                    this.infoOpened = false;
                    this.infoOpened = true;
                    this.$vs.notify({
                        color: 'success',
                        text:
                            `${this.checkCameraInMap ? this.$t('onMap.updateSuccess')  : this.$t('onMap.addSuccess')} ` + this.titleCamera + this.$t('onMap.successMap'),
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                    })
                    this.isOpacity = 0;
                    this.isJustUpdateLocation = true;
                    this.checkCameraInMap = true;
                    this.$eventBus.$emit('handle-reload-camera-layout', true);
                    this.$eventBus.$emit('handle-reload-tree', true);

                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            }
        },
        clickSaveChanger(){
            this.isOpacity = 0;
            if( this.isCameraDelete == false) {
                return this.$vs.notify({
                    color: 'danger',
                    text: this.$t('onMap.errDelete'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                })
            }
            if(this.checkCameraInMap == false && this.isJustDeleteLocation == true && this.isJustUpdateLocation == false && this.isCameraTitle == false){
                return this.$vs.notify({
                    color: 'danger',
                    text: this.$t('onMap.errDeleteInMap'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                })
            }
            if(this.checkCameraInMap == false && this.isJustDeleteLocation == true && this.isJustUpdateLocation == true && this.isCameraTitle == false ){
                return this.$vs.notify({
                    color: 'danger',
                    text: this.$t('onMap.errDeleteInMap'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                })
            }
            if(this.checkCameraInMap == false && this.isJustDeleteLocation == true ){
                return this.$vs.notify({
                    color: 'danger',
                    text: this.$t('onMap.errDeleteInMap'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                })
            }

            if(this.checkCameraInMap == false && this.isJustUpdateLocation == false){
                return this.$vs.notify({
                    color: 'danger',
                    text: this.$t('onMap.errDeleteInMap'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                })
            }
            if(this.isCameraDelete == true && this.checkCameraInMap == true && this.isJustDeleteLocation == false  && this.isJustUpdateLocation == true) {
                this.draggable = false;
                return this.$vs.dialog({
                    type: 'confirm',
                    color: 'danger',
                    title: this.$t('onMap.titleWarning'),
                    text: this.$t('onMap.textWarning') + this.titleCamera + ' ?',
                    accept: this.deleteMarkersCamera,
                    acceptText: this.$t('onMap.acceptText'),
                    cancelText: this.$t('onMap.cancelText')
                })
            }
            if(this.isCameraDelete == true && this.checkCameraInMap == true && this.isJustUpdateLocation == true && this.isJustDeleteLocation == false) {
                this.draggable = false;
                return this.$vs.dialog({
                    type: 'confirm',
                    color: 'danger',
                    title: this.$t('onMap.titleWarning'),
                    text: this.$t('onMap.textWarning') + this.titleCamera + ' ?',
                    accept: this.deleteMarkersCamera,
                    acceptText: this.$t('onMap.acceptText'),
                    cancelText: this.$t('onMap.cancelText')
                })
            }
            if(this.isCameraDelete == true && this.checkCameraInMap == true && this.isJustDeleteLocation == true  && this.isJustUpdateLocation == true) {
                this.draggable = false;
                return this.$vs.dialog({
                    type: 'confirm',
                    color: 'danger',
                    title: this.$t('onMap.titleWarning'),
                    text: this.$t('onMap.textWarning') + this.titleCamera + ' ?',
                    accept: this.deleteMarkersCamera,
                    acceptText: this.$t('onMap.acceptText'),
                    cancelText: this.$t('onMap.cancelText')
                })
            }
            if(this.isCameraDelete == true && this.checkCameraInMap == true && this.isJustDeleteLocation == false  && this.isJustUpdateLocation == false) {
                this.draggable = false;
                return this.$vs.dialog({
                    type: 'confirm',
                    color: 'danger',
                    title: this.$t('onMap.titleWarning'),
                    text: this.$t('onMap.textWarning') + this.titleCamera + ' ?',
                    accept: this.deleteMarkersCamera,
                    acceptText: this.$t('onMap.acceptText'),
                    cancelText: this.$t('onMap.cancelText')
                })
            }
            if( this.isJustDeleteLocation == true && this.isJustUpdateLocation == false) {
                return this.$vs.notify({
                    color: 'danger',
                    text: this.$t('onMap.errDeleteInMap'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                })
            }
        },
        deleteMarkersCamera(){
            const markerPayload = {
                latitude: null,
                longitude: null
            };
            this.$vs.loading();
            this.$crm.post(`/camera/update-lat-lng?cameraId=${this.cameraIdMarkers}`, markerPayload).then(() => {
                this.$vs.loading.close();
                this.infoContent = null;
                this.infoOpened = false;
                this.$vs.notify({
                    color: 'success',
                    text: this.$t('onMap.deleteSuccess'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                })
                this.isJustDeleteLocation = true;
                this.checkCameraInMap = false
                this.isCameraDelete = true;
                this.$eventBus.$emit('handle-reload-camera-layout', true);
                this.$eventBus.$emit('handle-reload-tree', true);
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        clickCancelChanger(){
            this.isEditOnMap = false;
            this.isEditOnMap = true;
            this.draggable = false;
            this.isOpacity = 0;
            this.$eventBus.$emit('handle-reload-tree', true);
            this.$eventBus.$emit('handle-reload-camera-layout', true);
        },
        isHandleEditOnMap(){
            if( this.isCameraDelete == true){
                this.isOpacity = 1;
            }
            this.isEditOnMap = false;
            this.draggable = true
        },
        nodeSelected(node) {
            let org = this.organizationBranchAll.find(x => x.id === node.value.organizationBranchId);
            this.center = {
                lat: org.latitude,
                lng: org.longitude
            }
            this.zoomMap = 14;
            this.valueCameraInTree = node;
            if(node.isCameraShare == true && node.isCamera == true){
                this.isCameraShare = true;            }
            if (!node.isCamera) {
                this.isCameraTitle = false;
                this.isOpacity = 0;
                this.titleCamera = node.title;
                this.isCameraDelete = false;
            } else {
                if(node.isCamera && !node.isCameraShare){
                    this.isCameraShare = false;
                    this.isJustDeleteLocation = false;
                    this.isCameraTitle = true;
                    this.isCameraDelete = true;
                    this.cameraIdMarkers = node.value.id;
                    if(node.value.latitude == null && node.value.longitude == null){
                        this.checkCameraInMap = false
                    } else {
                        this.checkCameraInMap = true
                    }
                    this.titleCamera = node.title;
                    if( this.isEditOnMap == false){
                        this.isOpacity = 1;
                    }
                    this.center =  {
                        lat: node.value.latitude,
                        lng: node.value.longitude
                    };
                    this.InputPlaceValue = '',
                    this.infoPosition = {
                        lat: node.value.latitude,
                        lng: node.value.longitude
                    };
                    if(node.value.longitude !== null && node.value.latitude !== null){
                        this.infoContent = node.title;
                        this.infoOpened = false;
                        this.infoOpened = true;
                        let org = this.organizationBranchAll.find(x => x.id === node.value.organizationBranchId);
                        this.contentFullAddress = org.fullAddress;
                        this.contentEmail = org.email;
                        this.contentNumberPhone = org.phoneNumber;
                        this.contentBranchName = org.organizationBranchName;
                        this.zoomMap = 18 + Number(node.value.longitude * 0.000000000001);
                    } else {
                        this.center = {
                            lat: 21.030653,
                            lng: 105.847130
                        }
                        this.zoomMap = 14;
                    }
                }
            }
        },
        getChild(node) {
        },
        getAddressData(addressData, placeResultData, id) {
            this.address = addressData;
        },
        handleError(error) {
            alert(error);
        },
        setPlace(place) {
            this.InputPlaceValue = place.formatted_address;
            this.currentPlace = place;
            this.zoomMap = 15;
            this.addMarker();
        },
        addMarker() {
            if (this.currentPlace) {
                const marker = {
                    lat: this.currentPlace.geometry.location.lat(),
                    lng: this.currentPlace.geometry.location.lng()
                };
                this.places.push(this.currentPlace);
                this.center = marker;
                this.currentPlace = null;
            }
        },
        geolocate: function () {
            navigator.geolocation.getCurrentPosition(position => {
                this.center = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
            });
        }
    }
}
</script>
<style lang="scss" scoped>

.title-camera {
    font-size: 22px;
}
.cameras-map-map__remove-area {
    align-items: center;
    background: hsla(0,100%,70%,.85);
    border-radius: 40px;
    bottom: 20px;
    box-sizing: border-box;
    color: #fff;
    display: flex;
    height: 80px;
    justify-content: center;
    left: 50%;
    margin-left: -200px;
    padding: 10px;
    position: absolute;
    text-align: center;
    width: 400px;
    z-index: 90;
}

.justify-content-right{
    justify-content: right;
}

</style>
<style lang="scss">
.scroll-area--nofications-dropdown, .scroll-area--cart-items-dropdowm {
    max-height: 64rem;
}
</style>
