<template>
    <div id="">
        <vs-popup class="editCamera" :title="$t('menuCamera.updateCamera')" :active.camel="openPopup">
            <div class="vx-row">
                <div class="vx-col md:w-1/2 w-full mt-2">
                    <label class="vs-input--label">{{ $t('menuCamera.branch') }}</label>
                    <v-select class="" v-model="branchModel" :disabled="isUpdate" :clearable="false"
                              :options="branchOptions"
                              data-vv-validate-on="blur" v-validate="'required'" name="branch">
                              <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                    </v-select>
                    <span class="text-danger text-sm">{{ errors.first('branch') }}</span>
                </div>
                <div class="vx-col md:w-1/2 w-full mt-2">
                    <vs-input class="w-full" :label="$t('menuCamera.cameraName')" v-model="cameraInfo.cameraName"
                              v-validate="'required'" name="cameraName"/>
                    <span class="text-danger text-sm">{{ errors.first('cameraName') }}</span>
                </div>
            </div>

            <div class="vx-row" id="customPaddingCamera">
                <div class="vx-col md:w-1/2 w-full mt-2">
                    <label class="vs-input--label">{{ $t('menuCamera.alias') }} </label>
                    <span class="text-danger">*</span>
                    <vs-input class="w-full" v-model="cameraInfo.aliasId" :disabled="isUpdate"
                              :placeholder="$t('menuCamera.enterCameraCode')"
                              data-vv-validate-on="blur"
                              v-validate="'required'" name="aliasId"/>
                    <span class="text-danger text-sm">{{ errors.first('aliasId') }}</span>
                </div>
                <div class="vx-col md:w-1/2 w-full mt-2">
                    <vs-input class="w-full" :label="$t('menuCamera.deviceCode')" v-model="cameraInfo.barcode"
                              :placeholder="$t('menuCamera.enterDeviceCode')"
                              name="barCode"/>
                </div>
                <div class="vx-col md:w-1/2 w-full mt-2">
                    <label class="vs-input--label">{{ $t('menuCamera.cameraBrand') }}</label>
                    <v-select v-model="manufactureId" :clearable="false" :options="manufactureOptions"
                              v-validate="'required'" name="manufactureId">
                        <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                    </v-select>
                    <span class="text-danger text-sm">{{ errors.first('manufactureId') }}</span>
                </div>
                <div class="vx-col md:w-1/2 w-full mt-2">
                    <label class="vs-input--label">{{ $t('menuCamera.streamFormat') }}</label>
                    <v-select v-model="streamFormat" :clearable="false"
                              :options="streamFormatOptions"
                              v-validate="'required'" name="streamFormat">
                              <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                    </v-select>
                    <span class="text-danger text-sm">{{ errors.first('streamFormat') }}</span>
                </div>

                <div class="vx-col md:w-full w-full mt-2">
                    <vs-button v-if="activeIconEye" type="flat" class="float-right mt-6" icon="icon-eye"
                               icon-pack="feather" icon-no-border @click="HiddenShowLinkStream"
                               style="margin-left: -68px"></vs-button>
                    <vs-button v-if="activeIconEyeOff" type="flat" class="float-right mt-6" icon="icon-eye-off"
                               icon-pack="feather" icon-no-border @click="HiddenShowLinkStream"
                               style="margin-left: -68px"></vs-button>
                    <vs-input class="w-full" :type="typeOption" label="Link stream" v-model="cameraInfo.streamUrl"
                              :placeholder="streamUrlPlateholder"
                              v-validate="'required'"  name="streamUrl" @change="isChangeLinkStream"
                              @input="inputChangeLink"/>
                    <span class="text-danger text-sm">{{ errors.first('streamUrl') }}</span>
                </div>
                <div class="vx-col w-full mt-2" v-if="isShowImageCamera">
                    <div class="component w-full">
                        <span class="font-medium">{{ $t('menuCamera.previewImage') }}</span>
                    </div>
                    <div class="component w-full mt-3 text-center">
                        <img id="image-base64-cameraInfo" class="w-full" :src="'data:image/jpeg;base64,'+ urlImage"/>
                    </div>
                </div>
            </div>
            <div class="vx-row">
                <div class="vx-col w-full">
                    <div class="mt-4 flex flex-wrap items-center justify-end">
<!--                        <vs-button class="ml-auto mt-2" :disabled='isDisablePreview' @click="showImageCamera">
                            {{ $t('button.preview') }}
                        </vs-button>-->
                        <vs-button class="ml-4 mt-2 text-size" icon-pack="feather" icon="icon-X"
                                   :disabled="!validateForm" @click="saveChanges">
                            {{$t('button.update')}}
                        </vs-button>
                        <vs-button class="ml-4 mt-2 text-size" type="border" @click="closePopup">{{$t('button.close')}}
                        </vs-button>
                    </div>
                </div>
            </div>
        </vs-popup>
    </div>
</template>

<script>
import vSelect from 'vue-select';

export default {
    components: {
        vSelect
    },
    data() {
        return {
            isDisablePreview: false,
            urlImage: null,
            isShowImageCamera: false,
            activeIconEye: true,
            activeIconEyeOff: false,
            typeOption: 'password',
            user_data: null,
            activeTab: 0,
            isUpdate: false,
            branchOptions: [],
            cameraInfo: {},
            aliasId: null,
            branchId: null,
            cameraId: null,
            manufactureOptions: [],
            streamFormatOptions: [
                {label: "RTSP", value: 2},
                {label: "RTMP", value: 3}
            ],
            streamUrlPlateholder: "rtsp://",
        }

    },
    props: {
        openPopup: {
            type: Boolean,
            default: false
        },
        closePopup: {
            type: Function
        },
    },
    computed: {
        manufactureId: {
            get() {
                return {
                    label: this.getLabelString(this.manufactureOptions.find(x => x.value == this.cameraInfo.manufactureId)),
                    value: this.cameraInfo.manufactureId
                }
            },
            set(obj) {
                this.cameraInfo.manufactureId = obj.value
            }
        },
        streamFormat: {
            get() {
                return {
                    label: this.getLabelString(this.streamFormatOptions.find(x => x.value == this.cameraInfo.streamFormat)),
                    value: this.cameraInfo.streamFormat
                }
            },
            set(obj) {
                this.cameraInfo.streamFormat = obj.value
                if (this.cameraInfo.streamFormat === this.streamFormatOptions[0].value) {
                    this.streamUrlPlateholder = "rtsp://";
                } else if (this.cameraInfo.streamFormat === this.streamFormatOptions[1].value){
                    this.streamUrlPlateholder = "rtmp://";
                }
            }
        },
        branchModel: {
            get() {
                return {
                    label: this.getLabelString(this.branchOptions.find(x => x.value == this.cameraInfo.organizationBranchId)),
                    value: this.cameraInfo.organizationBranchId
                }
            },
            set(obj) {
                this.cameraInfo.organizationBranchId = obj.value
            }
        },
        validateForm() {
            return !this.errors.any()
        }
    },
    methods: {
        inputChangeLink() {
            let change = this.cameraInfo.streamUrl.trim()
            return this.cameraInfo.streamUrl = change
        },
        isChangeLinkStream() {
            if (this.cameraInfo.streamUrl !== '') {
                this.isDisablePreview = false
            } else {
                this.isDisablePreview = true
            }
        },
        showImageCamera() {
            let url = `/camera/preview`,
                data = {
                    url: this.cameraInfo.streamUrl
                };
            if (this.cameraInfo.streamUrl == '') {
                this.isDisablePreview = true
            }
            this.$vs.loading();
            this.$crm.post(url, data).then((response) => {
                this.$vs.loading.close();
                if (!response.data) {
                    this.isShowImageCamera = false;
                    this.$vs.notify({
                        color: 'danger',
                        text: this.$t('textCamera.textLinkStream'),
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                    });
                }
                if (response.data) {
                    if (response.data.image == null && response.data.status == "disconnected") {
                        this.isShowImageCamera = false;
                        this.$vs.notify({
                            color: 'danger',
                            text: `${this.isUpdate ? this.$t('textCamera.errLinkStream') : this.$t('textCamera.errLinkStream')}`,
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                        });
                    }
                    if (response.data.image == null && response.data.status == "connected") {
                        this.isShowImageCamera = false;
                        this.$vs.notify({
                            color: 'danger',
                            text: `${this.isUpdate ? this.$t('textCamera.errLinkStream') : this.$t('textCamera.errLinkStream')}`,
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                        });
                    }
                    if (response.data.image !== null) {
                        this.urlImage = response.data.image;
                        this.isShowImageCamera = true;
                    }
                }
                this.$vs.loading.close();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        initScreen() {
            let branchList = JSON.parse(localStorage.getItem('branchInfoList') || '[]');

            this.branchOptions = branchList.map(x => {
                return {label: x.organizationBranchName, value: x.id}
            });
            this.branchId = this.$route.query.branch_id;
            this.cameraId = this.$route.query.id;
            if (this.cameraId == undefined) {
                return this.cameraInfo = {}
            }
            if (!this.cameraId)
                return this.cameraInfo = {
                    aliasId: null,
                    cameraName: '',
                    manufactureId: null,
                    streamFormat: 2,
                    streamUrl: '',
                    organizationBranchId: this.branchOptions.find(x => x.value === this.branchId) ? this.branchId : this.branchOptions[0].value
                };
        },
        getCamerabyIds: function (id) {
            this.isShowImageCamera = false;
            this.cameraId = id;
            this.$vs.loading();
            this.$crm.post(`/camera/find-by-id/${this.cameraId}`)
                .then((response) => {
                    this.$vs.loading.close();
                    if (response.data) {
                        this.isUpdate = true;
                        if (response.data.cameraManufacture == null) {
                            this.cameraInfo = {
                                aliasId: response.data.aliasId,
                                cameraName: response.data.cameraName,
                                cameraStatus: response.data.cameraStatus,
                                manufactureId: null,
                                organizationBranchId: response.data.organizationBranchId,
                                streamFormat: response.data.streamFormat,
                                streamUrl: response.data.streamUrl,
                                barcode: response.data.barcode,
                                id: response.data.id,
                            }
                        }
                        if (response.data.cameraManufacture !== null) {
                            this.cameraInfo = {
                                aliasId: response.data.aliasId,
                                cameraName: response.data.cameraName,
                                cameraStatus: response.data.cameraStatus,
                                manufactureId: response.data.cameraManufacture.id,
                                organizationBranchId: response.data.organizationBranchId,
                                streamFormat: response.data.streamFormat,
                                streamUrl: response.data.streamUrl,
                                barcode: response.data.barcode,
                                id: response.data.id,
                            }
                        }
                    }
                })
                .catch(() => {
                    this.$vs.loading.close();
                    this.cameraInfo = {
                        aliasId: null,
                        cameraName: '',
                        manufactureId: null,
                        streamFormat: 2,
                        streamUrl: '',
                        organizationBranchId: this.branchOptions.find(x => x.value.toString() === this.branchId) ? this.branchId : this.branchOptions[0].value
                    }
                });
        },
        getCameraManufacture() {
            this.$vs.loading();
            this.$crm.get(`/camera-manufacture/find-all`)
                .then((response) => {
                    this.$vs.loading.close();
                    if (response.data) {
                        this.manufactureOptions = response.data.map(item => {
                            return {value: item.id, label: item.name}
                        })
                    }
                })
                .catch(() => {
                    this.$vs.loading.close();
                    this.cameraInfo = {
                        aliasId: null,
                        cameraName: '',
                        manufactureId: null,
                        streamFormat: 2,
                        streamUrl: '',
                        organizationBranchId: this.branchOptions.find(x => x.value === this.branchId) ? this.branchId : this.branchOptions[0].value
                    }
                });
        },
        HiddenShowLinkStream() {
            this.activeIconEyeOff = !this.activeIconEyeOff
            if (this.typeOption == 'password') {
                this.typeOption = 'text'
            } else {
                this.typeOption = 'password'
            }
        },
        getLabelString(object) {
            return (object && object.label) || this.$t('textCamera.undefined');
        },
        async saveChanges(evt) {
            evt.preventDefault();
            try {
                if (!this.validateForm) return;

                const result = await this.$validator.validateAll();
                if (!result) return;

                this.cameraInfo.cameraStatus = 1;
                if (this.cameraInfo.manufactureId == "" || this.cameraInfo.manufactureId == null) {
                    this.cameraInfo.manufactureId = 0
                }
                let url = this.isUpdate ? `/camera/update/${this.cameraInfo.id}` : `/camera/create/${this.cameraInfo.organizationBranchId}`,
                    data = this.isUpdate ? this.cameraInfo : [this.cameraInfo];

                if (this.streamFormatOptions[0].value === this.cameraInfo.streamFormat) {
                    const regex = /(rtsp):\/\/(?:([^\s@\/]+)@)?([^\s\/:]+)(?::([0-9]+))?(?:\/(.*))?/;
                    let change = this.cameraInfo.streamUrl.trim();
                    let m;
                    if ((m = regex.exec(change)) !== null) {
                    } else {
                        return this.$vs.notify({
                            title: 'Lỗi !',
                            color: 'danger',
                            text: 'Link stream có định dạng không đúng.',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                        });
                    }
                }

                this.$vs.loading();
                this.$crm.post(url, data).then(() => {
                    this.openPopup = false;
                    this.$vs.notify({
                        color: 'success',
                        text: `${this.isUpdate ? this.$t('button.update') : this.$t('button.add')}` +' '+ this.$t('textCamera.cameraSuccess'),
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                    });
                    this.$vs.loading.close();
                    this.onClickCloseButtonEdit(true);
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                    this.onClickCloseButton(false);
                });
            } catch (ex) {
                console.log(ex);
            }
        },
        onClickCloseButtonEdit(val) {
            if(val == true) {
                this.$eventBus.$emit('handle-reload-tree', true);
            }
            this.openPopup = false;
            this.$emit('closePopupEditCamera', val)
        }
    },
    created() {
        this.initScreen();
        this.getCameraManufacture();
    },
    mounted() {
        this.$eventBus.$on('edit-camera', ($event) => {
            if ($event) this.getCamerabyIds($event.id);
            this.isShowImageCamera = false;
        })
        const next = this.$children[0].$refs.btnclose;
        next.$el.addEventListener('click', this.onClickCloseButtonEdit, false);
    }
}
</script>
<style lang="scss" scoped>
#image-base64-cameraInfo {
    //with: 480px;
    //height: 360px;
    justify-content: center;
    border-radius: 15px;
    box-shadow: 0px 0px 8px 4px rgba(var(--vs-primary),1);
    transition: transform .2s;
    background-color: white;
    height: 460px;
}
</style>
<style lang="scss">
.editCamera .vs-popup {
   width: 700px;
}

#customPaddingCamera {
    .vs-input--input.normal {
        padding: 0.7rem !important;
        padding-right: 36px !important;
        font-size: 1.07rem !important;
    }
}
</style>
