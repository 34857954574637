<template>
    <div>
        <vs-popup class="cameraPayment" :title="$t('menuPackage.payment')" :active.sync="openPopup">
            <div class="vx-row" v-if="this.paymentData">
                <!-- LEFT COL -->
                <div class="vx-col lg:w-2/3 w-full">
                    <vx-card class="heightCard">
                        <div class="vx-row">
                            <div class="vx-col md:w-1/3">
                                <img class="cursor-pointer" width="100%"
                                     :src="imgEmpty">
                            </div>
                            <div class="vx-col md:w-2/3 w-full">
                                <p class="text-grey mb-3">{{ $t('menuPackage.packageInformation') }}</p>
                                <vs-divider/>
                                <div class="vx-row mb-2">
                                    <span class="font-semibold">{{ $t('menuPackage.packageName') }}</span>
                                    <span
                                        class="ml-5 font-medium text-primary cursor-pointer">{{ this.paymentData.package.packageName.toUpperCase() }}</span>
                                </div>
                                <div class="vx-row mb-2 items-center">
                                    <span class="font-semibold">{{ $t('menuPackage.numberOfMonth') }}: </span>
                                    <vs-input-number min="1" max="12" :value="quantity"
                                                     @input="updateItemQuantity($event)" class="ml-5"/>
                                </div>
                                <div class="vx-row mb-5">
                                    <span class="font-semibold">{{ $t('menuPackage.price') }}: </span>
                                    <span
                                        class=" ml-5 font-medium text-primary cursor-pointer">{{ this.paymentData.package.amount != 0 ? formatPrice(this.paymentData.package.amount) : 0 }} vnđ</span>
                                </div>
                            </div>
                        </div>
                        <div class="vx-row">
                            <div class="flex w-full mt-5 mb-2 ml-5">
                                <span class="sm:flex hidden header-float"><b>{{ $t('menuPackage.packagePolicy')}}</b></span>
                            </div>
                            <div class="flex w-full">
                                <span class="sm:flex hidden ml-10">{{ $t('menuPackage.packagePolicy1')}}</span>
                            </div>
                            <div class="flex w-full mt-2 mb-2">
                                <span class="sm:flex hidden ml-10">{{ $t('menuPackage.packagePolicy2')}}</span>
                            </div>
<!--                            <div class="flex w-full mb-5">
                                <span class="sm:flex hidden ml-10">{{ $t('menuPackage.packagePolicy3')}}</span>
                            </div>-->
                        </div>
                    </vx-card>
                </div>
                <!-- RIGHT COL -->
                <div class="vx-col lg:w-1/3 w-full">
                    <vx-card class="heightCard">
                        <p class="text-grey mb-3">{{ $t('menuPackage.cameraInfo') }}</p>
                        <div class="flex justify-between mb-2 w-full">
                            <span class="font-semibold">{{ $t('menuPackage.cameraName') }}</span>
                            <span
                                class="font-medium text-primary cursor-pointer">{{ this.camera ? this.camera.cameraName.toUpperCase() : null }}</span>
                        </div>
                        <div class="flex justify-between mb-2 w-full">
                            <span class="font-semibold">{{ $t('menuPackage.packageName') }}</span>
                            <span
                                class="font-medium text-primary cursor-pointer">{{ this.paymentData.package.packageName.toUpperCase() }}</span>
                        </div>

                        <vs-divider/>

                        <p class="text-grey mb-3">{{ $t('menuPackage.payInfo') }}</p>
                        <div class="flex justify-between font-semibold mb-3 w-full">
                            <span>{{ $t('menuPackage.durationPack') }}</span>
                            <span
                                class="font-medium text-primary cursor-pointer">{{quantity}} {{ $t('menuPackage.month')}}</span>
                        </div>
                        <div class="flex justify-between font-semibold mb-3 w-full">
                            <span>{{ $t('menuPackage.totalPrice') }}</span>
                            <span
                                class="font-medium text-primary cursor-pointer">{{ formatPrice(this.paymentData.package.amount * quantity) }} {{ $t('menuPackage.vnd')}}</span>
                        </div>

                        <vs-divider/>
                        <p class="text-grey mb-3">{{ $t('momoPayment.payType') }}</p>
                        <div class="d-inline-block mb-2 flex items-center w-full">
                            <img class="cursor-pointer" width="44px"
                                 :src="imgMomo">
                            <vs-radio v-model="paymentMethod" vs-value="momo" class="font-semibold cursor-pointer ml-5">
                                {{ $t('menuPackage.momoWallet') }}
                            </vs-radio>
                        </div>
                        <div class="d-inline-block mb-5 flex items-center w-full">
                            <img class="cursor-pointer" width="44px"
                                 :src="imgAdmin">
                            <vs-radio v-model="paymentMethod" vs-value="admin" class="font-semibold cursor-pointer ml-5">
                                {{ $t('menuPackage.requestAdmin') }}
                            </vs-radio>
                        </div>
                        <vs-button class="w-full" @click="payment">{{ $t('menuPackage.payment') }}</vs-button>
                    </vx-card>
                </div>
            </div>

        </vs-popup>
    </div>
</template>

<script>
import imgEmpty from "../../../../assets/images/pages/login/backgroud-login.png";
import imgMomo from "../../../../assets/images/pages/momo_icon_circle_pinkbg.svg";
import imgAdmin from "../../../../assets/images/pages/img_admin.png";
import moment from "moment";

export default {
    components: {
        imgEmpty,
        imgMomo,
        imgAdmin,
        moment
    },
    data() {
        return {
            totalDateInMonth: 30,
            paymentMethod: 'momo',
            imgEmpty: null,
            imgMomo: null,
            imgAdmin: null,
            quantity: 1,
            quantityDate: null,
            paymentData: null,
            camera: null,
        }

    },
    props: {
        openPopup: {
            type: Boolean,
            default: false
        },
        closePopup: {
            type: Function
        },
    },
    watch: {
        openPopup(val) {
            if (val) {
                this.paymentMethod = 'momo';
                this.quantity = 1;
            }
        }
    },
    computed: {
        validateForm() {
            return !this.errors.any()
        }
    },
    methods: {
        toQuantityDate(){
            let date = new Date();
            date.setDate(date.getDate() + this.quantity * this.totalDateInMonth);
            this.quantityDate = moment(date).format("DD/MM/YYYY");
        },
        resetDefaultValueForm() {
            this.paymentMethod = 'momo';
            this.quantity = 1;
        },
        updateItemQuantity(e) {
            this.quantity = e;
            this.toQuantityDate()
        },
        initPayment() {
            this.getCameraById(this.paymentData.cameraId);
        },
        getCameraById(id) {
            this.$vs.loading.close();
            this.$crm.post(`/camera/find-by-id/${id}`)
                .then((response) => {
                    if (response.data) {
                        this.camera = response.data;
                    }
                })
                .catch(() => {
                    this.$vs.loading.close();
                });
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        async payment(evt) {
            evt.preventDefault();
            try {
                let data = {
                    organizationBranchId: this.paymentData.organizationBranchId,
                    cameraId: this.paymentData.cameraId,
                    cameraPackageId: this.paymentData.package.id,
                    time: this.quantity,
                    paymentType: this.paymentMethod
                }
                const res = await this.requestAdmin(data);
                if (res.status === 200) {
                    if (this.paymentMethod == 'momo') {
                        this.paymentMomo(res.data);
                    } else {
                        this.$emit('closePayment', false)
                        this.$vs.notify({
                            text: "Yêu cầu thanh toán thành công.",
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'success'
                        });
                    }
                } else {
                    this.$vs.notify({
                        text: res,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                }
            } catch (e) {
                console.log(e);
            }
        },
        paymentMomo(id) {
            this.$vs.loading();
            this.$crm.post(`/payment/momo/pay?id=${id}`).then((res) => {
                window.location.href = res.data;
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        requestAdmin(data) {
            return new Promise((resolve) => {
                let url = `/camera-package-history/request?` + new URLSearchParams(data).toString();
                this.$vs.loading();
                this.$crm.post(url).then((res) => {
                    this.$vs.loading.close();
                    resolve(res);
                }).catch((err) => {
                    this.$vs.loading.close();
                    resolve(err.response.data.message || err.response.data.error_description || err.response.data.error);
                });
            })
        },
        onClickClosePayment(val) {
            this.resetDefaultValueForm();
            this.$emit('closePayment', val)
        },
    },
    async created() {
        this.imgEmpty = imgEmpty;
        this.imgMomo = imgMomo;
        this.imgAdmin = imgAdmin;
        this.toQuantityDate();
    },
    mounted() {
        this.$eventBus.$on('package-camera-payment', ($event) => {
            this.paymentData = $event;
            this.initPayment();
        })
        const next = this.$children[0].$refs.btnclose;
        next.$el.addEventListener('click', this.onClickClosePayment, false);
    }
}
</script>

<style lang="scss">
.cameraPayment .vs-popup {
    width: 900px !important;
}

.heightCard {
    min-height: 450px;
}
</style>
