<template>
    <div class=" p-2 ml-2">
        <span class="header-float" v-if="this.packageHistory && this.packageHistory.endDuration">{{ $t('menuPackage.package') }}
            <b class="color">{{ this.packageHistory.cameraPackageResponseDTO.packageName }}</b>
            <span> {{ $t('menuPackage.forCamera') }} </span><b class="color">{{ cameraName }}</b> <span> {{ $t('menuPackage.forAlias') }} </span>
            <b class="color"> {{ this.packageHistory.alias }}</b>{{ $t('menuPackage.packageHistoryDate') }} <b class="color">{{ convertTimeHHmm(this.packageHistory.endDuration) }}</b>
        </span>
        <span v-if="this.packageHistory && !this.packageHistory.endDuration">{{ $t('menuPackage.package') }} <b
            class="color">{{ this.packageHistory.packageName }}</b> {{ $t('menuPackage.packageHistory') }}. {{ $t('menuPackage.renew') }} </span>

        <span class="header-float" v-if="!packageHistory">{{ $t('menuPackage.experience') }}</span>

        <div class="flex mt-5">
            <div class="flex-1 h-12 flex">
                <span class="sm:flex hidden m-auto"></span>
            </div>
            <div v-for="(item, ind) in organizationPackageList" class="flex-1 h-12 flex" :key="ind">
                <span
                    :class="packageHistory && item.id === packageHistory.cameraPackageResponseDTO.id ? 'sm:flex hidden m-auto warningPackage' : 'sm:flex hidden m-auto'"
                    style="font-weight: bold;">{{ item.packageName }}
                </span>
            </div>
        </div>

        <div class="flex" style="background-color: #f5f5f6">
            <div class="flex-1 h-12 flex">
                <span class="sm:flex hidden m-auto header-float"
                    style="font-weight: bold;">{{ $t('menuPackage.limit') }}
                </span>
            </div>
            <div v-for="(item, ind) in organizationPackageList" class="flex-1 h-12 flex" :key="ind">
                <span class="sm:flex hidden m-auto"></span>
            </div>
        </div>

        <div class="flex">
            <div class="flex-1 h-12 flex">
                <span
                    class="sm:flex hidden m-auto header-float">{{ $t('menuPackage.storageTime') }}
                </span>
            </div>
            <div v-for="(item, ind) in organizationPackageList" class="flex-1 h-12 flex" :key="ind">
                <span
                    :class="packageHistory && item.id === packageHistory.cameraPackageResponseDTO.id ? 'sm:flex hidden m-auto warningPackage':'sm:flex hidden m-auto'">{{ item.timeStorage }} {{ $t('menuPackage.day') }}
                </span>
            </div>
        </div>

        <div class="flex">
            <div class="flex-1 bg-grid-color-secondary h-12 flex">
                <span
                    class="sm:flex hidden m-auto header-float">{{ $t('menuPackage.storageCapacity') }}
                </span>
            </div>
            <div v-for="(item, ind) in organizationPackageList" class="flex-1 bg-grid-color-secondary h-12 flex" :key="ind">
                <span
                    :class="packageHistory && item.id === packageHistory.cameraPackageResponseDTO.id ? 'sm:flex hidden m-auto warningPackage':'sm:flex hidden m-auto'">{{ item.s3_quota }} GB
                </span>
            </div>
        </div>

        <div class="flex">
            <div class="flex-1 h-12 flex">
                <span class="sm:flex hidden m-auto header-float">{{ $t('menuPackage.quality') }}</span>
            </div>
            <div v-for="(item, ind) in organizationPackageList" class="flex-1 h-12 flex" :key="ind">
                <span :class="packageHistory && item.id === packageHistory.cameraPackageResponseDTO.id ? 'sm:flex hidden m-auto warningPackage':'sm:flex hidden m-auto'">{{
                    item.profile === 1 ? "360p" : item.profile === 2 ? " 480p" : item.profile === 3 ? " 640p" : item.profile === 8 ? " 720p" : item.profile === 9 ? " 1080p" : item.profile === 12 ? "HQ" : "" }}</span>
            </div>
        </div>

        <!-- <div class="flex mt-3" style="background-color: #f5f5f6">
            <div class="flex-1 h-12 flex">
                                <span class="sm:flex hidden m-auto header-float"
                                      style="font-weight: bold;">{{ $t('menuPackage.functional') }}</span>
            </div>
            <div v-for="(item, ind) in organizationPackageList" class="flex-1 h-12 flex" :key="ind">
                <span class="sm:flex hidden m-auto"></span>
            </div>
        </div> -->

        <!-- <div v-for="(p, i) in permissionList" :key="i">
            <div class="flex">
                <div class="flex-1 h-12 flex mt-5">
                    <span class="sm:flex hidden m-auto header-float">{{ p.functionAlias }}</span>
                </div>
                <div v-for="(item, ind) in organizationPackageList" class="flex-1 h-12 flex "
                     :key="ind">
                    <span class="sm:flex hidden m-auto">
                        <div class="flex-1 h-12 flex mt-5">
                                <feather-icon v-if="item[p.functionAlias.toString()]" icon="CheckIcon"
                                              :class="packageHistory && item.id == packageHistory.cameraPackageResponseDTO.id ? 'iconFeatherIs':'iconFeather'"
                                              style=""></feather-icon>
                                <feather-icon v-if="!item[p.functionAlias.toString()]" icon="XIcon"
                                              :class="packageHistory && item.id == packageHistory.cameraPackageResponseDTO.id ? 'iconFeatherRed':'iconFeatherRed'"
                                              style=""></feather-icon>
                        </div>
                    </span>
                </div>
            </div>
        </div> -->

        <div class="flex mt-5 mb-2" style="font-weight: bold;">
            <div class="flex-1 bg-grid-color-secondary h-12 flex">
                <span class="sm:flex hidden m-auto header-float">{{ $t('menuPackage.amount') }}</span>
            </div>
            <div v-for="(item, ind) in organizationPackageList"
                 class="flex-1 bg-grid-color-secondary h-12 flex" :key="ind">
                                <span style="color: rgba(var(--vs-primary),1);"
                                      :class="packageHistory && item.id === packageHistory.cameraPackageResponseDTO.id ? 'sm:flex hidden m-auto warningPackage':'sm:flex hidden m-auto'">{{ item.amount !== 0 ? formatPrice(item.amount) : 0 }} đ</span>
            </div>
        </div>

        <div class="flex" v-if="isUpdatePackage">
            <div class="flex-1 h-12 flex">
                <span class="sm:flex hidden m-auto header-float"></span>
            </div>
            <div v-for="(item, ind) in organizationPackageList" class="flex-1 h-12 flex" :key="ind">
                <div class="sm:flex hidden m-auto">
                    <vs-button
                        v-if="item.id !== 6"
                        @click="registerPackage(item)"
                        :color="packageHistory && item.id === packageHistory.cameraPackageResponseDTO.id ? 'warning' : 'primary'"
                        type="border">
                        {{ packageHistory ? $t('button.updatePackage') : $t('button.payment') }}
                    </vs-button>
                    <vs-button
                        v-if="item.id === 6"
                        @click="registerTrialPackage()" :disabled="amountTrial === 0"
                        :color="packageHistory && item.id === packageHistory.cameraPackageResponseDTO.id ? 'warning' : 'primary'"
                        type="border">
                        {{ amountTrial !== 0 ? $t('button.use') : $t('button.outOf') }}
                    </vs-button>
                </div>
            </div>
        </div>
        <div class="flex">
            <div class="flex-1 h-12 flex">
                <span class="sm:flex hidden m-auto header-float">
                    {{ $t('menuPackage.amountTrial') }}
                    <b class="mr-2">{{ (amountTrial) }}</b>
                </span>
            </div>
        </div>
        <div class="flex" v-if="!isUpdatePackage">
            <div class="flex-1 h-12 flex">
                <span class="sm:flex hidden m-auto header-float">
                    <b class="mr-2">{{ $t('menuPackage.note')}}</b>
                    {{ $t('menuPackage.noteValue') }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";

export default {
    components: {
    },
    data() {
        return {
            checkPackageName: null,
            components: {},
            organizationPackageList: [],
            permissionList: [],
            packageHistory: null,
            amountTrial: null,

            paymentData: null,

            isUpdatePackage: null,
            cameraName: null,
        }

    },
    props: {
        cameraId: {
            default: null
        },
        organizationBranchId: {
            default: null
        },
    },
    computed: {
    },
    methods: {
        /** reset value to open tab */
        async initScreen(){
            await this.getPermissionList();
            await this.getCameraPackage();
            await this.findAmountTrial();
            await this.findByCameraId(this.cameraId)
            await this.getPackageActive(this.cameraId);
            this.findPackageHistory(this.cameraId);
        },
        findAmountTrial() {
            new Promise((resolve, reject) => {
                this.$vs.loading()
                let userInfo = this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
                let organizationId = userInfo.organization.id
                this.$crm.get(`/trial-history/find-by-organization/${organizationId}`).then(response => {
                    this.$vs.loading.close()
                    if (response.data) {
                        this.amountTrial = response.data.amountTrial
                    }
                    resolve()
                }).catch(() => {
                    reject()
                    this.amountTrial = 0
                    this.$vs.loading.close();
                    // return this.$vs.notify({
                    //     text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    //     iconPack: 'feather',
                    //     icon: 'icon-alert-circle',
                    //     color: 'danger'
                    // });
                });
            })
        },

        getCameraPackage() {
            new Promise((resolve, reject) => {
                this.$vs.loading();
                this.$crm.get('/camera-package/find-all').then((response) => {
                    this.organizationPackageList = response.data.sort((a, b) => a.timeStorage - b.timeStorage);
                    this.permissionList.forEach(permission => {
                        let functionAlias = permission.functionAlias;
                        this.organizationPackageList.forEach(p => {
                            p[functionAlias.toString()] = false;
                            let permissions = p.permissionFunctions;
                            if (permissions && permissions.length > 0) {
                                let per = permissions.find(per => per.id === permission.id);
                                p[functionAlias.toString()] = !!per;
                            } else {
                                p[functionAlias.toString()] = false;
                            }
                        })
                    });
                    this.organizationPackageList = this.organizationPackageList.sort(function (a, b) {
                        return parseInt(a.amount) - parseInt(b.amount);
                    })
                    // this.organizationPackageList = this.organizationPackageList.filter(x => x.id != 1 && x.id !=3)
                    this.$vs.loading.close();
                    resolve(true)
                }).catch((err) => {
                    reject()
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            })
        },
        getPermissionList() {
            return new Promise((resolve, reject) => {
                this.$vs.loading();
                this.$crm.post('/permission-function/find-all').then((response) => {
                    this.permissionList = response.data;
                    this.$vs.loading.close();
                    resolve(true);
                }).catch((err) => {
                    this.$vs.loading.close();
                    reject(false);
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            });
        },
        getPackageActive(id) {
            if(!id) return;
            this.packageHistory = null;
            return new Promise((resolve, reject) => {
                this.$vs.loading();
                let url = `/camera/find-package-last-active?cameraId=${id}`;
                this.$crm.get(url).then((response) => {
                    if (response.data) {
                        this.checkPackageName = response.data.cameraPackageResponseDTO.packageName;
                        this.packageHistory = response.data;
                        this.$vs.loading.close();
                    }
                    resolve(true);
                    this.$vs.loading.close();
                }).catch((err) => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                    reject(false);
                });
            })
        },
        findPackageHistory(id) {
            if (id) {
                this.isUpdatePackage = null;
                this.$vs.loading();
                let url = '/camera-package-history/find-by-org?cameraId=' + id;
                this.$crm.get(url).then((response) => {
                    this.$vs.loading.close()
                    if (!response.data || response.data.length === 0) {
                        this.isUpdatePackage = true;
                    } else {
                        let waitingPackage = response.data.filter(x => x.status === 4);
                        this.isUpdatePackage = waitingPackage.length === 0;
                    }
                }).catch((err) => {
                    this.$vs.loading.close()
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            }
        },
        findByCameraId(cameraId){
            return new Promise(resolve => {
                this.$crm.post(`/camera/find-by-id/${cameraId}`).then((response) => {
                    if (response.data) {
                        this.cameraName = response.data.cameraName
                        resolve(response.data);
                    }
                })
                .catch(() => {
                    resolve(null);
                    this.$vs.loading.close();
                });
            })
        },

        convertTimeHHmm(t) {
            return moment(t).format('DD/MM/YYYY HH:mm:ss');
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        registerTrialPackage() {
            let payload = {
                cameraId: this.cameraId,
                cameraPackageId: 6,
                organizationBranchId: this.organizationBranchId,
                paymentType: 'admin',
                time: 1
            }

            this.$crm.post(`/camera-package-history/request?` + new URLSearchParams(payload).toString()).then(() => {
                this.$vs.loading.close()
                this.$emit('closePopupPackageCamera', false)
                return this.$vs.notify({
                    text: this.$t('menuPackage.requestTrial'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'success'
                })
            }).catch((err) => {
                this.$vs.loading.close()
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                })
            })
        },
        async registerPackage(item) {
            let camera = await this.findByCameraId(this.cameraId);
            if(!camera){
                return this.$vs.notify({
                    text: this.$t('warningMenuOrganization.anErr'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            }
            if (camera && camera.evgCamera.streamingStatus === 1) {
                return this.$vs.notify({
                    text: this.$t('textCamera.disconnectCameraErr'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            }
            this.paymentData = {
                cameraId: this.cameraId,
                package: item,
                permissions: this.permissionList,
                packageActive: this.packageHistory,
                organizationBranchId: this.organizationBranchId,
            }
            this.$eventBus.$emit('package-camera-payment', this.paymentData);
            this.closeAndOpenPayment();
        },
        closeAndOpenPayment() {
            this.$emit('closeAndOpenPayment', false)
        },
    },
    created() {
        this.initScreen();
    },
}
</script>

<style lang="scss" scoped>
.colorTitle {
    color: #ff9f43 !important;
}

.iconFeather {
    width: 1.2rem;
    color: green;
}

.iconFeatherRed {
    width: 1.2rem;
    color: red;
}

.iconFeatherIs {
    width: 1.2rem;
    color: #ff9f43 !important;
}

.warningPackage {
    color: #ff9f43 !important;
}

.bg-grid-color-secondary {
    background-color: #dae1e729;
}

[dir] .m-auto {
    color: #2a3035;
}

.header-float {
    margin-left: 10px !important;
}

.vx-card__title > h4 {
    font-weight: bold;
}

@media (max-width: 1366px) {
    #page-user-list {
        margin-left: -13px;
    }
}
</style>
