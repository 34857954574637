<template>
    <div id="">
        <vs-popup class="cameraDetail" :title="$t('optionalCards.detailCamera')" :active.camel="openPopup">
            <div class="vx-row p-2">
                <div class="vx-col w-full">
                    <div class="component w-full flex flex-wrap">
                        <div class="vx-col md:w-1/4 mt-2">
                            <feather-icon class="align-feather mr-2" icon-pack="feahter" icon="ColumnsIcon"></feather-icon>
                            <span class="font-medium">{{ $t('menuCamera.cameraName') }}:</span>
                        </div>
                        <div class="vx-col detailborder p-2">
                            <span class="mx-4">{{ camera.cameraName }}</span>
                        </div>
                    </div>
                    <div class="component w-full flex flex-wrap mt-4">
                        <div class="vx-col md:w-1/4 mt-2">
                            <feather-icon class="align-feather mr-2" icon-pack="feahter" icon="ApertureIcon"></feather-icon>
                            <span class="font-medium">{{ $t('menuCamera.alias') }}:</span>
                        </div>
                        <div class="vx-col detailborder p-2">
                            <span class="mx-4">{{ camera.aliasId }}</span>
                        </div>
                    </div>
                    <div class="component w-full flex flex-wrap mt-4">
                        <div class="vx-col md:w-1/4 mt-2">
                            <feather-icon class="align-feather mr-2" icon-pack="feahter"
                                      icon="GitBranchIcon"></feather-icon>
                            <span class="font-medium">{{ $t('menuCamera.branch') }}: </span>
                        </div>
                        <div class="vx-col detailborder p-2">
                            <span class="mx-4">{{ getNameBranch(camera) }}</span>
                        </div>
                    </div>
                    <div class="component w-full flex flex-wrap mt-4">
                        <div class="vx-col md:w-1/4 mt-2">
                            <feather-icon class="align-feather mr-2" icon-pack="feahter"
                                      :icon="camera.evgCamera && camera.evgCamera.streamingStatus ? 'VideoIcon' : 'VideoOffIcon' || 'VideoIcon'"></feather-icon>
                            <span class="font-medium">{{ $t('menuCamera.cameraStatus') }}: </span>
                        </div>
                        <div class="vx-col detailborder p-2">
                            <span class="mx-4">{{
                                getStatusCamera(camera.evgCamera ? camera.evgCamera.streamingStatus : null)
                            }}</span>
                        </div>
                    </div>
                </div>
                <div class="vx-col w-full">
                    <div class="component w-full flex flex-wrap mt-4">
                        <div class="vx-col md:w-1/4 mt-2">
                            <feather-icon class="align-feather mr-2" icon-pack="feahter" icon="TagIcon"></feather-icon>
                            <span class="font-medium">{{ $t('menuCamera.cameraBrand') }}: </span>
                        </div>
                        <div class="vx-col detailborder p-2">
                            <span class="mx-4">{{
                                camera.cameraManufacture && camera.cameraManufacture.name || this.$t('textCamera.undefined')
                            }}</span>
                        </div>
                    </div>
                    <div class="component w-full flex flex-wrap mt-4">
                        <div class="vx-col md:w-1/4 mt-2">
                            <feather-icon class="align-feather mr-2" icon-pack="feahter" icon="LinkIcon"></feather-icon>
                            <span class="font-medium">Link camera: </span>
                        </div>
                        <div class="vx-col detailborder p-2">
                            <span class="mx-4">{{ getStreamURL(camera) || this.$t('textCamera.unknown') }}</span>
                            <feather-icon @click="showStreamURL(camera)" class="align-feather mr-2 float-right" icon-pack="feahter"
                                          :icon="showURL ? 'EyeIcon' : 'EyeOffIcon'"></feather-icon>
                        </div>
                    </div>
                </div>
            </div>
            <div class="vx-row">
                <div class="vx-col w-full mt-4 mb-2 flex flex-wrap items-center justify-end">
                    <vs-button class="ml-auto" type="border" @click="closePopup">{{$t('button.close')}}</vs-button>
                </div>
            </div>
        </vs-popup>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            camera: {},
            listBranch: [],
            showURL: false
        }
    },
    props: {
        openPopup: {
            type: Boolean,
            default: false
        },
        closePopup: {
            type: Function
        }
    },
    methods: {
        showStreamURL: function (camera) {
            this.showURL = !this.showURL;
            this.getStreamURL(camera);
        },
        getStreamURL: function (camera) {
            let url = '';
            if (this.showURL) url = camera.streamUrl;
            else {
                if (camera.streamUrl) {
                    for (let i = 0; i < camera.streamUrl.length; i++) {
                        url += '*';
                    }
                }
            }
            return url;
        },
        getCameraById: function (id) {
            this.onClickCloseButtonDetail();
            this.$crm.post(`/camera/find-by-id/${id}`)
                .then((response) => {
                    this.$vs.loading.close();
                    if (response.data) {
                        this.camera = response.data;
                    }
                })
                .catch(() => {
                    this.$vs.loading.close();
                    this.camera = {
                        cameraName: '',
                        manufacturer: '',
                        streamFormat: '0',
                        streamUrl: '',
                        organizationBranchId: this.branchOptions.find(x => x.value.toString() === this.branchId) ? this.branchId : this.branchOptions[0].value
                    }
                });
        },
        getNameBranch(camera) {
            let currentBranch = this.listBranch.find(t => t.id == camera.organizationBranchId);
            if (currentBranch) {
                return currentBranch.organizationBranchName;
            }
            return this.$t('textCamera.unknown')
        },
        getStatusCamera(cameraStatus) {
            if (cameraStatus == 0) return this.$t('textCamera.disConnect');
            if (cameraStatus == 1) return this.$t('textCamera.connected');
            else return this.$t('textCamera.waitingConnection');
        },
        initScreen() {
            this.$crm.post('/organization-branch/all').then((response) => {
                this.listBranch = response.data;
            }).catch((err) => {
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        onClickCloseButtonDetail() {
            this.openPopup = false;
            this.$emit('closePopupDetail')
        }
    },
    created() {
        this.initScreen();
    },
    mounted() {
        this.$eventBus.$on('detail-camera', ($event) => {
            if ($event) this.getCameraById($event.id);
        })
        const next = this.$children[0].$refs.btnclose;
        next.$el.addEventListener('click', this.onClickCloseButtonDetail, false);
    }
}
</script>

<style scoped>
.align-feather {
    vertical-align: bottom;
    width: 1.2rem;
}
</style>

<style lang="scss">
.cameraDetail .vs-popup {
    width: 700px !important;
    height: auto;
}

.detailborder {
    border: 1px solid rgba(0, 0, 0, 0.2);
    height:35px;
    width: 620px;
    border-radius: 20px;
}
</style>
