<template>
    <div class="vx-row services">
        <div class="vx-col w-full table mt-4 test" v-if="packageHistory && packageHistory.alias">
            <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                <!--Title-->
                <div class="py-4">
                    <h3 class="ml-6 title">{{ $t('packageHistory.infoPackageHistory') }}</h3>
                </div>
                <!--endTitle-->
                <!--Table-->
                <tbody>
                <p class="service-text mt-2 font-bold ">{{ $t('packageHistory.serviceDescription') }}</p>
                <tr class="">
                    <td class="px-2 font-semibold td-padding-left-more">
                        <div class="inline-flex">
                            <feather-icon class="p-3 rounded-full opacity02 video-icon" icon="VideoIcon"
                                          svgClasses="h-5 w-5"/>
                        </div>
                        {{ $t('packageHistory.packageHistoryName') }}
                    </td>
                    <td class="px-2 td-padding-left">
                        {{ packageHistory.cameraName }}
                    </td>
                </tr>

                <tr class="">
                    <td class="px-2  font-semibold td-padding-left-more">
                        <div class="inline-flex">
                            <feather-icon class="p-3 rounded-full opacity02 camera-icon" icon="CameraIcon"
                                          svgClasses="h-5 w-5"/>
                        </div>
                        {{ $t('packageHistory.cameraIdentifier') }}
                    </td>
                    <td class="px-2 td-padding-left">
                        {{ packageHistory.alias }}
                    </td>
                </tr>
                <tr class="">
                    <td class="px-2 font-semibold td-padding-left-more">
                        <div class="inline-flex">
                            <feather-icon class="p-3 rounded-full opacity02 package-icon" icon="PackageIcon"
                                          svgClasses="h-5 w-5"/>
                        </div>
                        {{ $t('packageHistory.servicePack') }}
                    </td>
                    <td class="px-2 td-padding-left">
                        {{ packageHistory.packageName }}
                    </td>
                </tr>
                <tr class="">
                    <td class="px-2 font-semibold td-padding-left-more">
                        <div class="inline-flex ">
                            <feather-icon class="p-3 rounded-full opacity02 calendar-icon" icon="CalendarIcon"
                                          svgClasses="h-5 w-5"/>
                        </div>
                        {{ $t('packageHistory.since') }}
                    </td>
                    <td class="px-2 td-padding-left">
                        {{ packageHistory.startDuration }}
                    </td>
                </tr>
                <tr class="">
                    <td class="px-2 font-semibold td-padding-left-more">
                        <div class="inline-flex">
                            <feather-icon class="p-3 rounded-full opacity02 archive-icon" icon="ArchiveIcon"
                                          svgClasses="h-5 w-5"/>
                        </div>
                        {{ $t('packageHistory.toDate') }}
                    </td>
                    <td class="px-2 td-padding-left">
                        {{ packageHistory.endDuration }}
                    </td>
                </tr>
                <tr class="">
                    <td class="px-2 font-semibold td-padding-left-more">
                        <div class="inline-flex">
                            <feather-icon class="p-3 rounded-full opacity02 user-icon" icon="UserIcon"
                                          svgClasses="h-5 w-5"/>
                        </div>
                        {{ $t('packageHistory.status') }}

                    </td>
                    <td class="td-padding-left">
                        <vs-chip :color="chipColor(packageHistory.status)">
                            <span>{{ organizationTypeString(packageHistory.status) }}</span>
                        </vs-chip>
                    </td>
                </tr>
                </tbody>
                <!--endTable-->
                <!--Cancel-->
                <div class="py-2 w-full my-2 md:w-1/3 cancel">
                    <vs-button class="w-full" @click="abortConfirm(packageHistory.status)">
                        {{ $t('packageHistory.cancel') }}
                    </vs-button>
                </div>
                <!--End Cancel-->
            </div>
        </div>
        <div v-else class="vx-col w-full text-center">
            <span class="text-danger">Hiện tại không có thông tin xử lý gói dịch vụ. Vui lòng sử dụng gói dịch vụ trước khi xem chi tiết ở phần này!</span>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import vSelect from 'vue-select';
import {AgGridVue} from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import Pagination from "../../../ui-elements/Pagination.vue"
import CellRendererActions from "./cell-renderer/CellRendererActions.vue"
import CellRendererStatus from "./cell-renderer/CellRendererStatus";
import moment from "moment";

export default {
    components: {
        vSelect,
        AgGridVue,
        Vue,
        // Cell Renderer
        CellRendererActions,
        Pagination,
        CellRendererStatus
    },
    data() {
        return {

            cameraIds: null,
            organizationBranchId: null,
            searchQuery: "",
            maxPageNumbers: 7,
            // AgGrid
            gridApi: null,
            gridOptions: {},
            defaultColDef: {},
            // Cell Renderer Components
            components: {
                CellRendererActions,
                CellRendererStatus
            },
            branchId: null,
            isUpdate: null,
            selectedAction: null,
            actions: [
                {value: null, label: this.$t('cameraHistory.registerPackage.action.all')},
                {value: 0, label: this.$t('cameraHistory.registerPackage.action.create')},
                {value: 1, label: this.$t('cameraHistory.registerPackage.action.deactivated')},
                {value: 2, label: this.$t('cameraHistory.registerPackage.action.reactivated')},
                {value: 3, label: this.$t('cameraHistory.registerPackage.action.delete')},
                {value: 4, label: this.$t('cameraHistory.registerPackage.action.updateLinkStream')},
                {value: 5, label: this.$t('cameraHistory.registerPackage.action.updateTimeStorage')},
                {value: 6, label: this.$t('cameraHistory.registerPackage.action.updateInformation')},
            ],
            idCancel: null,
            checkstatus: null,
            packageHistory: null,
            cameraData: null,
        }
    },
    props: {
        closePopup: {
            type: Function
        },
        cameraValue: {},
        openPopup: {
            type: Boolean,
            default: false
        },

    },
    methods: {
        formatPrice(value) {
            let val = (value / 1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        onClickCloseButtonPackageHistory(val) {
            if (val === true) {
                this.$eventBus.$emit('handle-reload-tree', true);
            }
            this.$emit('closePopupPackageCamera', val)
        },
        abortConfirm(status) {
            this.checkstatus = status;
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: this.checkstatus === 0 ? this.$t('cameraHistory.title.cancelConfirmation') : this.$t('cameraHistory.title.cancelConfirmation'),
                text: this.checkstatus === 0 ? `Bạn có chắc chắn muốn hủy chờ xử lý gói dịch vụ cho camera ${this.packageHistory.cameraName}?` : `Bạn có chắc chắn muốn hủy sử dụng gói dịch vụ cho camera ${this.packageHistory.cameraName}`,
                accept: this.abort,
                acceptText: this.$t('button.agree'),
                cancelText: this.$t('button.cancel')
            })
        },
        abort() {
            let url = this.checkstatus === 0 ? `/camera-package-history/cancel?id=${this.packageHistory.id}` : `/camera-package-history/abort?cameraId=${this.packageHistory.cameraId}`;
            this.$vs.loading();
            this.$crm.put(url).then(() => {
                this.$vs.loading.close();
                this.$eventBus.$emit('handle-reload-tree')
                this.$vs.notify({
                    text: this.checkstatus === 0 ? 'Hủy chờ xử lý gói dịch vụ thành công!' : 'Huỷ sử dụng gói dịch vụ thành công!',
                    iconPack: 'feather',
                    icon: 'icon-check-circle',
                    color: 'success',
                });
                this.onClickCloseButtonPackageHistory();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        onClickCloseButtonPackage(val) {
            this.cameraIds = null;
            this.activeTab = 0;
            if (val === true) {
                this.$eventBus.$emit('handle-reload-tree', true);
            }
            this.$emit('closePopupPackageCamera', val)
        },
        closeModal() {
            this.cameraIds = null;
            this.activeTab = 0;
            this.$emit('closePopupPackageCamera', false)
        },
        closePopupPackageCamera() {
            this.cameraIds = null;
            this.activeTab = 0;
            this.$emit('closePopupPackageCamera', false)
        },
        //Call API PackageHistory
        initScreen(id) {
            if (id) {
                this.packageHistory = null;
                this.$vs.loading();
                this.$crm.get(`/camera/find-package-last-active?cameraId=${id}`).then(async (response) => {
                    if (!response.data) {
                        this.getPackageHistory(id);
                    } else {
                        let packageCamera = response.data.cameraPackageResponseDTO;
                        this.packageHistory = {
                            cameraId: id,
                            alias: response.data.alias,
                            packageName: packageCamera.packageName,
                            startDuration: moment(response.data.startDuration).format("DD/MM/YYYY HH:mm:ss"),
                            endDuration: moment(response.data.endDuration).format("DD/MM/YYYY HH:mm:ss"),
                            status: 1,
                            cameraName: response.data.cameraName,
                        };
                    }
                    this.$vs.loading.close();
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            }
        },
        getPackageHistory(id) {
            this.$crm.get(`/camera-package-history/find-by-org?cameraId=${id}`).then((response) => {
                if (response.data && response.data.length > 0) {
                    this.packageHistory = response.data[0];
                    this.packageHistory.packageName = this.packageHistory.cameraPackage.packageName
                    this.packageHistory.amount = this.packageHistory.amount.amount ? this.formatPrice(this.packageHistory.amount.amount) : 0;
                    this.packageHistory.startDuration = moment(this.packageHistory.modifiedDate).format("DD/MM/YYYY HH:mm:ss");
                }
            }).catch((err) => {
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
    },
    watch: {
        openPopup: function (val) {
            if (val && this.cameraValue) {
                this.initScreen(this.cameraValue);
            }
        },
        '$store.state.isUpdateOrgPackageRequestPackage'(val) {
            if (val) {
                this.initScreen(val);
                this.$store.commit('UPDATE_REQUEST_PACKAGE', false);
            }
        }
    },
    computed: {
        validateForm() {
            return !this.errors.any()
        },
        organizationTypeString() {
            return (value) => {
                switch (value) {
                    case 0:
                        return this.$t('statusActive.waitingApprove');
                    case 1:
                        return this.$t('statusActive.agree');
                    case 2:
                        return this.$t('statusActive.decline');
                    case 3:
                        return this.$t('statusActive.cancel');
                    case 4:
                        return this.$t('statusActive.waitingExpire');
                    case 5:
                        return this.$t('statusActive.abort');
                    case 6:
                        return this.$t('statusActive.expries');
                    case 7:
                        return this.$t('statusActive.failure');
                    default :
                        return ''
                }
            }
        },
        chipColor() {
            return (value) => {
                switch (value) {
                    case 0:
                        return "";
                    case 1:
                        return "success";
                    case 2:
                        return "danger";
                    case 3:
                        return "warning";
                    case 4:
                        return "success";
                    case 5:
                        return "danger";
                    case 6:
                        return ""
                }
            }
        },
    },
    mounted() {
        if (this.cameraValue) {
            this.initScreen(this.cameraValue)
        }
        this.$eventBus.$on('close-modal-package', () => {
            this.onClickCloseButtonPackageHistory(false);
        })
    },
}
</script>
<style scoped>
/*Mobile*/
@media only screen and (min-width: 320px) {
    .table {
        font-size: 9px !important;
    }

    .td-padding-left {
        padding-left: 55px !important;
    }

    .cancel {
        width: 100%;
    }

    .service-text {
        margin-left: 20px;
    }
}

/*Mobile*/
@media only screen and(min-width: 481px) {
    .table {
        font-size: 12px !important;
    }

    .service-text {
        margin-left: 5rem;
    }
}

/*Table*/
@media only screen and (min-width: 641px) {
    .table {
        font-size: 14px !important;
    }

    .td-padding-left {
        padding-left: 150px !important;
    }

    .td-padding-left-more {
        padding-left: 60px !important;
    }

    .cancel {
        float: right;
        margin: 0 35%;
    }

    .service-text {
        margin-left: 5rem;
    }
}

/*Laptops*/
@media only screen and (min-width: 961px) {
    .table {
        margin: 0 25%;
        justify-items: center;
        align-items: center;
        font-size: 14px !important;
    }

    .cancel {
        float: right;
        margin: 0 35%;
    }

    .service-text {
        margin-left: 5rem;
    }
}

/*Icons*/
.camera-icon {
    color: green;
}

.calendar-icon {
    color: peru;
}

.package-icon {
    color: purple;
}

.archive-icon {
    color: #005aff;
}

.video-icon {
    color: orangered;
}

.user-icon {
    color: black;
}

/*feather-icon*/
.camera-icon {
}

/*Font-size*/
h3 {
    font-size: 20px;
}

.title {
}

/*button cancel*/
.bg-primary h3 {
    color: white !important;
}

.test {
    align-items: center !important;
}

.test p {
    vertical-align: middle !important;
}
</style>
