<template>
    <div class="vx-col md:w-full sm:w-auto text-center">
        <vx-tooltip :text="$t('button.cancel')" position="top" v-if="params.data.status == 0">
            <feather-icon icon="XIcon" class="icon-action1"
                          svgClasses="h-5 w-5 mr-4 hover:text-danger cursor-pointer"
                          @click="confirmDeleteRecord"/>
        </vx-tooltip>

        <vx-tooltip :text="$t('button.abort')" position="top" v-if="params.data.status == 1 || params.data.status == 4">
            <feather-icon icon="XSquareIcon" class="icon-action1"
                          svgClasses="h-5 w-5 mr-4 hover:text-danger cursor-pointer"
                          @click="abortConfirm"/>
        </vx-tooltip>
    </div>
</template>

<script>
export default {
    name: 'CellRendererActions',

    data() {
        return {
            camera: {}
        }
    },

    created() {
        this.$crm.post(`/camera/find-by-id/${this.params.data.cameraId}`).then((response) => {
                this.$vs.loading.close();
                if (response.data) {
                    this.camera = response.data;
                }
            })
            .catch(() => {
                this.$vs.loading.close();
                this.camera = {
                    cameraName: '',
                    manufacturer: '',
                    streamFormat: '0',
                    streamUrl: '',
                    organizationBranchId: this.branchOptions.find(x => x.value.toString() === this.branchId) ? this.branchId : this.branchOptions[0].value
                }
            });
    },

    methods: {

        confirmDeleteRecord() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: this.$t('cameraHistory.title.cancelConfirmation'),
                text: this.$t('cameraHistory.text.deleteConfirm'),
                accept: this.deleteRecord,
                acceptText: this.$t('button.agree'),
                cancelText: this.$t('button.cancel')
            })
        },

        deleteRecord() {
            this.$vs.loading();
            this.$crm.put(`/camera-package-history/cancel?id=${this.params.data.id}`).then(() => {
                this.$vs.loading.close();
                this.$eventBus.$emit('close-modal-package')
                return this.showDeleteSuccess();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },

        showDeleteSuccess() {
            this.$store.commit('UPDATE_REQUEST_PACKAGE', true);
            this.$vs.notify({
                color: 'success',
                text: this.$t('cameraHistory.text.cancelPackUpgSuccess'),
                iconPack: 'feather',
                icon: 'icon-alert-circle',
            })
        },

        abortConfirm() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: this.$t('cameraHistory.title.cancelConfirmation'),
                text: `${this.$t('cameraHistory.text.abortConfirm')}"${this.params.data.packageName}"?`,
                accept: this.abort,
                acceptText: this.$t('button.agree'),
                cancelText: this.$t('button.cancel')
            })
        },

        showAbortSuccess() {
            this.$store.commit('UPDATE_REQUEST_PACKAGE', this.params.data.cameraId);
            this.$vs.notify({
                color: 'success',
                text: `${this.$t('cameraHistory.text.abortSuccess')}"${this.params.data.packageName}"`,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
            })
        },

        abort() {
            this.$vs.loading();
            let data = {
                cameraId: this.params.data.cameraId,
            }
            let url = `/camera-package-history/abort?` + new URLSearchParams(data).toString();
            this.$crm.put(url).then(() => {
                this.$vs.loading.close();
                //this.$eventBus.$emit('close-modal-package')
                this.$eventBus.$emit('handle-reload-tree')
                return this.showAbortSuccess();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        }
    }
}
</script>
<style scoped>
.icon-center {
    text-align: center;
}

.icon-action {
    color: rgba(var(--vs-primary),1);
}

.icon-action1 {
    color: red;
}

.icon-action1:hover {
    color: red;
    opacity: 0.5;
}

</style>

